<template>
  <div class="card card-product-detail product-price-chart-wrapper">
    <div class="card-header">
      <span class="product-price-min-max d-none d-md-block" v-if="minPrice < maxPrice">
                <span>Giá thấp nhất: <span class="product-price-min">{{ minPrice }}</span></span>
                <span v-if="priceCurrent"> – Hiện tại: <span
                  class="product-price-current">{{ priceCurrent | formatPrice }}</span></span>
                <span class="product-price-separator"> – </span>
                <span>Giá cao nhất: <span class="product-price-max">{{ maxPrice }}</span></span>
            </span>
      <span v-else>
        Không có biến động giá
      </span>
    </div>
    <div class="card-body mt-6">
      <p class="d-block d-md-none">&nbsp;</p>
      <highcharts class="chart" v-if="chartOptions" :options="chartOptions"
                  :highcharts="hcInstance"/>
    </div>
  </div>
</template>

<script>
  import Highcharts from 'highcharts'
  import { Chart } from 'highcharts-vue'
  import { formatCurrency, roundPrice, timestampToDate } from '@/helper/FormatHelper'
  import { isMobile } from '@/helper/DeviceHelper'

  export default {
    name: 'ProductPriceChart',
    props: [
      'productHistoryPrice',
      'priceCurrent'
    ],
    components: {
      highcharts: Chart
    },
    data: function () {
      return {
        hcInstance: Highcharts,
        chartOptions: null,
        minPrice: null,
        maxPrice: null
      }
    },
    async mounted() {
      this.hcInstance.setOptions({
        lang: {
          numericSymbols: [` Nghìn VNĐ`, ` Triệu VNĐ`, ` Tỉ VNĐ`]
        }
      });
      this.reDraw()
    },
    methods: {
      async reDraw() {
        // console.log('price chart: ', this.productHistoryPrice)
        let color = '#ffbb97'
        let categories = []
        this.productHistoryPrice.price_ts.map((item) => {
          categories.push(timestampToDate(item, 'dd-MM-yyyy'))
        })

        let priceValueMin = Math.min(...this.productHistoryPrice.price)
        let priceValueMinIdx = this.productHistoryPrice.price.lastIndexOf(priceValueMin)
        let priceValueMax = Math.max(...this.productHistoryPrice.price)
        let priceValueMaxIdx = this.productHistoryPrice.price.indexOf(priceValueMax)
        let tune_coef = 0.5
        let min_for_chart = roundPrice(Math.max(0, priceValueMin - priceValueMin * Math.min(tune_coef, priceValueMin / (0.01 + priceValueMax - priceValueMin))))
        let max_for_chart = roundPrice(priceValueMax + (1 - tune_coef) * (priceValueMin - min_for_chart))

        let uniquePrices = new Set([...this.productHistoryPrice.price])

        this.minPrice = formatCurrency(priceValueMin)
        this.maxPrice = formatCurrency(priceValueMax)

        let isMobileDevice = isMobile()

        let seriesData = []
        this.productHistoryPrice.price.map((item, i) => {
          let itemColor = color
          let isMin = false
          let isMax = false
          if (i === priceValueMinIdx) {
            isMin = true
            itemColor = '#00ab6c'
          }

          if (i === priceValueMaxIdx) {
            isMax = true
            itemColor = '#f00'
          }

          if (isMobileDevice) {
            if (uniquePrices.size >= 2) {
              if (i === priceValueMinIdx) {
                isMin = true
                itemColor = '#00ab6c'
              }

              if (i === priceValueMaxIdx) {
                isMax = true
                itemColor = '#f00'
              }
            }
          }

          seriesData.push({
            color: itemColor,
            y: item,
            isMin,
            isMax
          })
        })

        const renderMinTooltip = (that) => {
          return `<span class="product-price-chart-tooltip product-price-chart-tooltip-min">Giá thấp nhất: <b class="product-price-min" style="color:#00ab6c">${formatCurrency(that.y)}</b></span>`
        }

        const renderMaxTooltip = (that) => {
          return `<span class="product-price-chart-tooltip product-price-chart-tooltip-max">Giá cao nhất: <b class="product-price-max" style="color:#f00">${formatCurrency(that.y)}</b></span>`
        }

        // console.log(categories.length * 30);

        let numberPointXAxis =  3
        if (screen.width > 600) {
          numberPointXAxis = 5
        }
        if (screen.width > 400) {
          numberPointXAxis = 4
        }
        this.chartOptions = {
          chart: {
            type: 'spline',
            height: 300,
            scrollablePlotArea: null,
            events: {
              load: isMobileDevice ? null : function () {
                if (uniquePrices.size < 2) {
                  return
                }
                let currentTooltipOption = this.options.tooltip
                this.myTooltipMin = new Highcharts.Tooltip(this, {
                  ...currentTooltipOption,
                  formatter: function () {
                    return renderMinTooltip(this)
                  }
                })
                this.myTooltipMin.refresh(this.series[0].points[priceValueMinIdx])
                this.myTooltipMax = new Highcharts.Tooltip(this, {
                  ...currentTooltipOption,
                  formatter: function () {
                    return renderMaxTooltip(this)
                  }
                })
                this.myTooltipMax.refresh(this.series[0].points[priceValueMaxIdx])
              }
            }
          },
          colors: [color, color, color, color, color],
          xAxis: {
            categories: categories,
            title: {
              text: ''
            },
            labels: {
              step: (seriesData?.length - seriesData?.length % numberPointXAxis) / numberPointXAxis + 1,
            }
          },
          yAxis: {
            title: {
              text: 'Giá bán'
            },
            startOnTick: true,
            min: min_for_chart,
            max: max_for_chart
          },
          series: [{
            data: seriesData
          }],
          title: {
            text: ''
          },
          legend: {
            enabled: false
          },
          credits: {
            enabled: false
          },
          plotOptions: {
            column: {
              borderWidth: 0,
              groupPadding: 0,
              shadow: false,
              borderRadius: 5,
              states: {
                select: {
                  enabled: false
                }
              }
            },
            series: {
              allowPointSelect: true
            }
          },
          tooltip: {
            borderRadius: 5,
            useHTML: true,
            formatter: function () {
              if (this.point.isMin) {
                return renderMinTooltip(this)
              } else if (this.point.isMax) {
                return renderMaxTooltip(this)
              } else {
                return `<span class="product-price-chart-tooltip" style="background: white; z-index: 999">Giá ngày <b>${this.x}</b> là <b>${formatCurrency(this.y)}</b></span>`
              }
            },
            backgroundColor: '#fff'
          }
        }
      }
    },
    watch: {
      productHistoryPrice() {
        this.reDraw()
      },
    },
    filters: {
      formatPrice: function (price) {
        if (price == null || price === '') {
          return ''
        }
        return formatCurrency(price)
      }
    }
  }
</script>

<style lang="scss" scoped>
  $beeOrange: #ff7227;
  $colorGood: #00ab6c;
  $colorNormal: $beeOrange;
  $colorBad: #ff342c;

  .card-header {
    padding: .75rem 0;
    margin-bottom: 0;
    color: inherit;
    /*background-color: rgba(0, 0, 0, .03);*/
    /*border-bottom: 1px solid rgba(0, 0, 0, .125);*/
  }

  .card.card-product-detail {
    /*margin-top: 30px;*/
    border: 0;
    /*box-shadow: 0 0 1px rgba(0, 0, 0, 0.4);*/
    padding: 0;
    overflow: hidden;

    .card-header {
      border-color: #eee;
      height: 50px;

      svg {
        color: #666;
        vertical-align: text-top;
        zoom: 1.15;
      }
    }
  }

  .product-price-chart-wrapper {
    .card-header {
      position: relative;

      .product-price-min-max {
        position: absolute;
        /*top: 12px;*/
        /*right: 20px;*/
      }
    }

    .product-price-min-max {
      line-height: 24px;
      font-size: 14px;
      display: flex;
      font-weight: normal;
      color: #666;

      .product-price-separator {
        margin: 0 4px;
        font-weight: normal;
      }
    }
  }

  .product-price-min {
    color: $colorGood;
    font-weight: bold;
  }

  .product-price-max {
    color: $colorBad;
    font-weight: bold;
  }

  .product-price-current {
    color: $colorNormal;
    font-weight: bold;
  }

  .highcharts-tooltip span {
    z-index: 998;
    background: #fff;
  }

  .highcharts-tooltip + .highcharts-tooltip ~ .highcharts-tooltip span {
    z-index: 999;
  }

</style>
