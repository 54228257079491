<template>
  <div>
    <a-table
      :columns="columns"
      :row-key="(record, index) => index"
      :data-source="products"
      :scroll="isDesktop ? { y: 360 } : { y: 400 }"
      :pagination="false"
      class="table-product-price-alert"
      style="box-shadow: 0px 4px 6px rgba(171, 171, 171, 0.25)"
    >
      <div
        slot="product"
        slot-scope="product"
        class="font-medium"
        :class="{ disabled: product.disabled }"
      >
        <div class="flex">
          <div class="mr-1">
            <img
              :src="product.url_thumbnail"
              :width="isDesktop ? '69px' : '34px'"
            />
          </div>
          <div class="product-info">
            <a
              class="text-xs md:text-sm mb-1 text-black"
              :class="{
                'line-clamp__3': isDesktop,
                'line-clamp__2': !isDesktop,
                'font-medium': isDesktop,
                'font-normal': !isDesktop,
              }"
              :href="getProductDirectUrl(product)"
              target="_blank"
              :style="{ lineHeight: isDesktop ? '17px' : '14px' }"
              :title="product.name"
            >
              {{ product.name }}
            </a>
            <a
              class="text-xs font-normal flex hover:text-primary-900"
              v-if="isDesktop"
              :href="product.shop_url"
              target="_blank"
              style="color: #9e9e9e"
              :title="product.shop_name"
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style="transform: translateY(1px)"
                class="mr-1"
              >
                <path
                  d="M10.8886 11.6658H6.2219V8.55469H5.44412V11.6658H3.11079V8.55469H2.33301V11.6658C2.33301 11.8721 2.41495 12.0699 2.56081 12.2158C2.70668 12.3616 2.90451 12.4436 3.11079 12.4436H10.8886C11.0948 12.4436 11.2927 12.3616 11.4385 12.2158C11.5844 12.0699 11.6663 11.8721 11.6663 11.6658V8.55469H10.8886V11.6658Z"
                  fill="currentColor"
                />
                <path
                  d="M13.1401 5.15969L11.5535 1.98635C11.4891 1.85677 11.3898 1.74772 11.2668 1.67146C11.1439 1.59521 11.0021 1.55476 10.8574 1.55469H3.14179C2.9971 1.55476 2.85529 1.59521 2.73231 1.67146C2.60934 1.74772 2.51007 1.85677 2.44568 1.98635L0.859016 5.15969C0.804843 5.26838 0.776874 5.38825 0.77735 5.50969V6.70747C0.776995 6.88919 0.840287 7.06531 0.956239 7.20524C1.12761 7.40168 1.33925 7.55893 1.5768 7.6663C1.81435 7.77366 2.07222 7.82863 2.33291 7.82747C2.75826 7.82813 3.17064 7.68104 3.49957 7.41135C3.8285 7.68119 4.24079 7.82867 4.66624 7.82867C5.09169 7.82867 5.50398 7.68119 5.83291 7.41135C6.16183 7.68119 6.57413 7.82867 6.99957 7.82867C7.42502 7.82867 7.83731 7.68119 8.16624 7.41135C8.49517 7.68119 8.90746 7.82867 9.33291 7.82867C9.75835 7.82867 10.1706 7.68119 10.4996 7.41135C10.8667 7.71283 11.3365 7.86021 11.8101 7.82249C12.2837 7.78478 12.7242 7.56492 13.039 7.20913C13.1564 7.06974 13.2211 6.89357 13.2218 6.71135V5.50969C13.2223 5.38825 13.1943 5.26838 13.1401 5.15969ZM5.83291 5.59913V6.19024L5.51402 6.61024C5.41613 6.74367 5.28819 6.85217 5.14056 6.92695C4.99294 7.00174 4.82978 7.04071 4.66429 7.04071C4.49881 7.04071 4.33565 7.00174 4.18803 6.92695C4.0404 6.85217 3.91246 6.74367 3.81457 6.61024L3.49957 6.17469V5.59913L4.50679 2.33247H6.2218L5.83291 5.59913ZM10.4996 6.17469L10.1846 6.61024C10.0867 6.74367 9.95874 6.85217 9.81112 6.92695C9.6635 7.00174 9.50034 7.04071 9.33485 7.04071C9.16936 7.04071 9.00621 7.00174 8.85858 6.92695C8.71096 6.85217 8.58302 6.74367 8.48513 6.61024L8.16624 6.17469V5.59913L7.77735 2.33247H9.50791L10.4996 5.59913V6.17469Z"
                  fill="currentColor"
                />
              </svg>
              {{ product.shop_name }}
            </a>
          </div>
        </div>
        <div
          class="flex items-center mt-1"
          v-if="!isDesktop"
          style="color: #9e9e9e; font-size: 10px"
        >
          <img
            :src="getPlatformLogoByProductId(product)"
            class="img-platform mr-1"
          />
          {{ product.shop_name }}
        </div>
        <div class="text-red-500 mt-1 text-xs" v-if="product.disabled">
          <a-icon type="exclamation-circle" />
          Sản phẩm không còn tồn tại
        </div>
      </div>
      <div
        slot="market"
        slot-scope="product"
        class="font-medium"
        :class="{ disabled: product.disabled }"
      >
        <img :src="getPlatformLogoByProductId(product)" class="img-platform" />
      </div>
      <div slot="price" slot-scope="product" class="text-center">
        <div
          class="font-medium whitespace-nowrap mb-3"
          :class="{
            'text-danger': product.price === uniqueMaxPrice,
            'text-success': product.price === uniqueMinPrice,
            disabled: product.disabled,
          }"
        >
          {{ product.price | formatCurrency }}
        </div>
        <div
          class="text-primary-500 cursor-pointer"
          @click="onClickHistoryPrice(product)"
        >
          <a-icon class="mr-1" type="line-chart" />
          Biến động
        </div>
      </div>
      <div
        slot="delta_percent"
        slot-scope="product"
        :class="{ disabled: product.disabled }"
      >
        <div>
          <a-icon type="arrow-up" v-if="product.delta_percent > 0" />
          <a-icon type="arrow-down" v-if="product.delta_percent < 0" />
          {{
            product.delta_percent !== 0
              ? Math.abs(product.delta_percent) + "%"
              : 0
          }}
        </div>
      </div>
      <div
        slot="delta_price"
        slot-scope="product"
        :class="[{ disabled: product.disabled }]"
      >
        {{ product.delta_price | formatDiffPrice }}
      </div>
    </a-table>
    <a-modal
      title=""
      :closable="true"
      :footer="null"
      v-model="showPopupProductHistory"
      :maskClosable="true"
      :width="960"
    >
      <PopupProductHistory
        v-if="productBaseIdCurrent"
        :product-base-id="productBaseIdCurrent"
        :popup-product-history-tab="popupProductHistoryTab"
      />
    </a-modal>
  </div>
</template>

<script>
import { mixinStoreGetterGlobal } from "@/mixin/store/MixinGlobal";
import { getUrlProductDirect } from "@/service/product/UrlProductService";
import { ALL_PLATFORM_BASE_OBJECT } from "@/constant/general/GeneralConstant";
import { getUrlGPhotoSize } from "@/helper/GPhotoHelper";
import PopupProductHistory from "@/views/product/PopupProductHistory";
import { formatCurrency } from "@/helper/FormatHelper";

const columnsDesktop = [
  {
    title: "Tên sản phẩm",
    dataIndex: "",
    key: "name",
    width: "35%",
    scopedSlots: { customRender: "product" },
  },
  {
    title: "Nơi bán",
    dataIndex: "",
    key: "market",
    width: "13%",
    scopedSlots: { customRender: "market" },
    align: "center",
  },
  {
    title: "Giá bán",
    dataIndex: "",
    key: "price",
    scopedSlots: { customRender: "price" },
    width: "17%",
    align: "center",
  },
  {
    title: "Phần trăm lệch mốc theo dõi (%)",
    dataIndex: "",
    key: "delta_percent",
    scopedSlots: { customRender: "delta_percent" },
    width: "20%",
    align: "center",
  },
  {
    title: "Giá lệch mốc theo dõi",
    dataIndex: "",
    key: "delta_price",
    scopedSlots: { customRender: "delta_price" },
    width: "18%",
    align: "center",
  },
];
const columnsMobile = [
  {
    title: "Tên sản phẩm",
    dataIndex: "",
    key: "name",
    width: "45%",
    scopedSlots: { customRender: "product" },
  },
  {
    title: "Giá bán",
    dataIndex: "",
    key: "price",
    scopedSlots: { customRender: "price" },
    width: "30%",
    align: "center",
  },
  {
    title: "Lệch mốc theo dõi (%)",
    dataIndex: "",
    key: "delta_percent",
    scopedSlots: { customRender: "delta_percent" },
    width: "25%",
    align: "center",
  },
];

const getMarketNameByProductId = (productId) => {
  if (productId.startsWith("1__")) {
    return "shopee";
  }
  if (productId.startsWith("2__")) {
    return "lazada";
  }
  if (productId.startsWith("3__")) {
    return "tiki";
  }
  if (productId.startsWith("4__")) {
    return "sendo";
  }
};

export default {
  name: "TableProductPriceAlert",
  filters: {
    formatCurrency,
    formatDiffPrice: (price) => {
      if (price === 0) return 0 + "₫";
      if (price > 0) {
        return `+${formatCurrency(price)}`;
      }
      return formatCurrency(price);
    },
  },
  props: {
    products: {
      type: Array,
    },
  },
  data() {
    return {
      columns: [],
      popupProductHistoryTab: null,
      productBaseIdCurrent: null,
      showPopupProductHistory: null,
    };
  },
  components: {
    PopupProductHistory,
  },
  created() {
    this.columns = this.isDesktop ? columnsDesktop : columnsMobile;
  },
  computed: {
    uniqueMaxPrice() {
      const prices = this.products.map((product) => product.price);
      const maxPrice = Math.max(...prices);
      const isUnique =
        prices.filter((price) => price === maxPrice).length === 1;
      return isUnique ? maxPrice : null;
    },
    uniqueMinPrice() {
      const prices = this.products.map((product) => product.price);
      const minPrice = Math.min(...prices);
      const isUnique =
        prices.filter((price) => price === minPrice).length === 1;
      return isUnique ? minPrice : null;
    },
  },
  methods: {
    getPlatformLogoByProductId(product) {
      const platform =
        ALL_PLATFORM_BASE_OBJECT[
          getMarketNameByProductId(product.product_base_id)
        ];
      return getUrlGPhotoSize(platform.urlLogo);
    },
    getProductDirectUrl(product) {
      return getUrlProductDirect(product.product_base_id, product.name);
    },
    onClickHistoryPrice(product) {
      this.popupProductHistoryTab = "history_price";
      this.productBaseIdCurrent = product["product_base_id"];
      this.showPopupProductHistory = true;
    },
  },
  mixins: [mixinStoreGetterGlobal],
};
</script>

<style lang="scss" scoped>
.img-platform {
  width: 14px;
  height: 14px;
  border-radius: 9999px;
  object-fit: cover;
}

@media (min-width: 576px) {
  .img-platform {
    width: 20px;
    height: 20px;
  }
}

.text-danger {
  color: #f50000;
}

.text-success {
  color: #00c259;
}

.ant-table {
  background: #ffffff;
  box-shadow: 0px 4px 6px rgba(171, 171, 171, 0.25);
  border-radius: 4px;

  .product-name {
    @apply text-sm font-medium text-black;
  }
}
.disabled {
  color: #9e9e9e;
  .product-info,
  .price {
    color: #9e9e9e !important;
  }
  .product-info a {
    color: #9e9e9e !important;
  }

  img {
    opacity: 0.5;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    filter: grayscale(100%);
  }
}
</style>

<style lang="scss">
.table-product-price-alert {
  .ant-table-thead > tr > th {
    background-color: #f2f2f2;
  }
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 8px 8px;
}

@media (min-width: 576px) {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 16px 16px;
  }
}
</style>
