import { URL_BACKEND_API } from "@/config/ConfigEndpoint";

const urlApiGetPriceAlertList = ({ page, limit, order_by = "" }) => {
  return (
    `${URL_BACKEND_API}/market/price_alert/list` +
    `?page=${page}&limit=${limit}&order_by=${order_by}`
  );
};

const urlApiCreatePriceAlert = () => {
  return `${URL_BACKEND_API}/market/price_alert`;
};

const urlApiUpdatedPriceAlert = () => {
  return `${URL_BACKEND_API}/market/price_alert`;
};

const urlApiGetPriceAlertDetail = id => {
  return `${URL_BACKEND_API}/market/price_alert?price_alert_id=` + id;
};

const urlApiVerifyProductBase = value => {
  return `${URL_BACKEND_API}/market/price_alert/check_product?value=` + value;
};

const urlApiVerifyProductList = () => {
  return `${URL_BACKEND_API}/market/price_alert/check_product`;
};
const urlApiDeletePriceAlert = id => {
  return `${URL_BACKEND_API}/market/price_alert?price_alert_id=` + id;
};

export {
  urlApiGetPriceAlertList,
  urlApiCreatePriceAlert,
  urlApiUpdatedPriceAlert,
  urlApiGetPriceAlertDetail,
  urlApiVerifyProductBase,
  urlApiVerifyProductList,
  urlApiDeletePriceAlert
};
