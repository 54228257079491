<template>
  <div class="card card-product-detail product-order-chart-wrapper">
    <div class="card-body">
      <p class="d-block d-md-none">&nbsp;</p>
      <highcharts class="chart" v-if="chartOptions" :options="chartOptions"
                  :highcharts="hcInstance"/>
    </div>
  </div>
</template>

<script>
  import Highcharts from 'highcharts'
  import { Chart } from 'highcharts-vue'
  import { formatCurrency, roundPrice, timestampToDate } from '@/helper/FormatHelper'
  import { isMobile } from '@/helper/DeviceHelper'

  export default {
    name: 'ProductPriceChart',
    props: [
      'productHistoryOrder',
      'orderCountCurrent'
    ],
    components: {
      highcharts: Chart
    },
    data: function () {
      return {
        hcInstance: Highcharts,
        chartOptions: null,
        minPrice: null,
        maxPrice: null
      }
    },
    async mounted() {
      this.hcInstance.setOptions({
        lang: {
          numericSymbols: [` Nghìn Sp`, ` Triệu Sp`, ` Tỉ Sp`]
        }
      });
      this.draw()
    },
    methods: {
      async draw() {
        // console.log('order chart:', this.productHistoryOrder)
        let color = '#91d5ff'
        let categories = []
        this.productHistoryOrder.order_count_ts.map((item) => {
          categories.push(timestampToDate(item, 'dd-MM-yyyy'))
        })

        let orderCountValueMin = Math.min(...this.productHistoryOrder.order_count)
        let orderCountValueMinIdx = this.productHistoryOrder.order_count.lastIndexOf(orderCountValueMin)
        let orderCountValueMax = Math.max(...this.productHistoryOrder.order_count)
        let orderCountValueMaxIdx = this.productHistoryOrder.order_count.indexOf(orderCountValueMax)
        let tune_coef = 0.5
        let min_for_chart = roundPrice(Math.max(0, orderCountValueMin - orderCountValueMin * Math.min(tune_coef, orderCountValueMin / (0.01 + orderCountValueMax - orderCountValueMin))))
        let max_for_chart = roundPrice(orderCountValueMax + (1 - tune_coef) * (orderCountValueMin - min_for_chart))

        let uniquePrices = new Set([...this.productHistoryOrder.order_count])

        this.minPrice = orderCountValueMin
        this.maxPrice = orderCountValueMax

        let isMobileDevice = isMobile()

        let seriesData = []
        this.productHistoryOrder.order_count.map((item, i) => {
          let itemColor = color
          let isMin = false
          let isMax = false
          // if (i === orderCountValueMinIdx) {
          //   isMin = true
          //   itemColor = '#00ab6c'
          // }
          //
          // if (i === orderCountValueMaxIdx) {
          //   isMax = true
          //   itemColor = '#f00'
          // }
          //
          // if (isMobileDevice) {
          //   if (uniquePrices.size >= 2) {
          //     if (i === orderCountValueMinIdx) {
          //       isMin = true
          //       itemColor = '#00ab6c'
          //     }
          //
          //     if (i === orderCountValueMaxIdx) {
          //       isMax = true
          //       itemColor = '#f00'
          //     }
          //   }
          // }

          seriesData.push({
            color: itemColor,
            y: item,
            isMin,
            isMax
          })
        })

        let numberPointXAxis =  3
        if (screen.width > 600) {
          numberPointXAxis = 5
        }
        if (screen.width > 400) {
          numberPointXAxis = 4
        }
        this.chartOptions = {
          chart: {
            type: 'area',
            height: 300,
            scrollablePlotArea: null,
            events: {}
          },
          colors: [color, color, color, color, color],
          xAxis: {
            categories: categories,
            labels: {
              step: (seriesData?.length - seriesData?.length % numberPointXAxis) / numberPointXAxis + 1,
            }
          },
          yAxis: {
            title: {
              text: 'Tổng đơn'
            },
            startOnTick: true,
            // min: min_for_chart,
            // max: max_for_chart
          },
          series: [{
            data: seriesData
          }],
          title: {
            text: ''
          },
          legend: {
            enabled: false
          },
          credits: {
            enabled: false
          },
          plotOptions: {
            column: {
              borderWidth: 0,
              groupPadding: 0,
              shadow: false,
              borderRadius: 5,
              states: {
                select: {
                  enabled: false
                }
              }
            },
            series: {
              allowPointSelect: true
            }
          },
          tooltip: {
            borderRadius: 5,
            useHTML: true,
            formatter: function () {
              return `<span class="product-order-chart-tooltip" style="background: white; z-index: 999">Tổng đơn tính đến ngày <b>${this.x}</b> là <b>${this.y}</b></span>`
            },
            backgroundColor: '#fff'
          }
        }
      }
    },
    watch: {
      productHistoryOrder() {
        this.draw()
      },
    },
    filters: {
      formatPrice: function (orderCount) {
        if (orderCount == null || orderCount === '') {
          return ''
        }
        return formatCurrency(orderCount)
      }
    }
  }
</script>

<style lang="scss" scoped>
  $beeOrange: #ff7227;
  $colorGood: #00ab6c;
  $colorNormal: $beeOrange;
  $colorBad: #ff342c;

  .card-header {
    padding: .75rem 0;
    margin-bottom: 0;
    color: inherit;
    /*background-color: rgba(0, 0, 0, .03);*/
    /*border-bottom: 1px solid rgba(0, 0, 0, .125);*/
  }

  .card.card-product-detail {
    margin-top: 30px;
    border: 0;
    /*box-shadow: 0 0 1px rgba(0, 0, 0, 0.4);*/
    padding: 0;
    overflow: hidden;

    .card-header {
      border-color: #eee;
      height: 50px;

      svg {
        color: #666;
        vertical-align: text-top;
        zoom: 1.15;
      }
    }
  }

  .product-order-chart-wrapper {
    .card-header {
      position: relative;

      .product-order-min-max {
        position: absolute;
        /*top: 12px;*/
        /*right: 20px;*/
      }
    }

    .product-order-min-max {
      line-height: 24px;
      font-size: 14px;
      display: flex;
      font-weight: normal;
      color: #666;

      .product-order-separator {
        margin: 0 4px;
        font-weight: normal;
      }
    }
  }

  .product-order-min {
    color: $colorGood;
    font-weight: bold;
  }

  .product-order-max {
    color: $colorBad;
    font-weight: bold;
  }

  .product-order-current {
    color: $colorNormal;
    font-weight: bold;
  }

  .highcharts-tooltip span {
    z-index: 998;
    background: #fff;
  }

  .highcharts-tooltip + .highcharts-tooltip ~ .highcharts-tooltip span {
    z-index: 999;
  }

</style>
