<template>
  <a-modal :closable="false"
           :footer="null"
           :centered="true"
           v-model="popupConfirm"
           :maskClosable="false"
           width="350px"
  >
    <div class="flex flex-col justify-center items-center w-full mt-2 text-base">
      <slot name="content">
        <p>
          <a-icon type="question-circle" class="text-primary-500 mr-3"/>
          <span class="text-black">{{ text }}</span>
        </p>
        <p v-if="description" class="text-center text-sm 00 mt-4" style="color: #9A9A9A">
          {{ description }}
        </p>
      </slot>
      <div class="flex justify-center mt-5">
        <a-button
          class="text-gray-400 mr-2"
          @click="onClickCancel" :disabled="loading"
          style="width: 98px"
        >
          {{ cancelText }}
        </a-button>
        <a-button
          type="primary"
          @click="onClickOk"
          :loading="loading"
          style="width: 98px"
        >
          {{ okText }}
        </a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: "PopupConfirm",
  props: {
    text: {
      default: 'Bạn muốn tiếp tục chứ?'
    },
    description: {
      default: ''
    },
    cancelText: {
      default: 'Huỷ'
    },
    okText: {
      default: 'Tiếp tục'
    },
    isShownPopup: {
      default: false
    },
    loading: {
      default: false
    },
  },
  methods: {
    onClickOk() {
      this.$emit('ok')
    },
    onClickCancel() {
      this.$emit('cancel')
    }
  },
  watch: {
    isShownPopup(value) {
      this.popupConfirm = value
    }
  },
  data() {
    return {
      popupConfirm: this.isShownPopup
    }
  }
}
</script>

<style scoped>

</style>
