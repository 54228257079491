
<template>
  <a-modal
    :closable="true"
    :footer="null"
    :centered="true"
    v-model="visible"
    :maskClosable="false"
    @cancel="onClickCancel"
    :width="568"
  >
    <div class="create-price-alert-popup">
      <div class="text-lg text-primary-500 mb-4 font-medium">
        {{ !editPriceAlert ? "Theo dõi giá mới" : "Thông tin theo dõi giá" }}
      </div>
      <div class="add-product-input">
        <div class="text-base font-medium text-black mb-2">
          Mốc cần theo dõi
        </div>
        <a-input-search
          class="search-input"
          placeholder="Vui lòng nhập số tiền hoặc link sản phẩm tham chiếu"
          @search="onVerifyBaseProduct"
          v-model="referenceProduct.inputValue"
          :loading="loading"
          @change="onChangeReferenceProduct"
          :allowClear="true"
        >
          <a-button slot="enterButton" type="primary">Thêm</a-button>
        </a-input-search>
        <div
          v-if="referenceProduct.error"
          class="text-red-500 mt-2"
          style="font-size: 13px"
        >
          <a-icon type="exclamation-circle" />
          {{ referenceProduct.error }}
        </div>
        <div v-if="referenceProduct.reference_price" class="mt-3">
          Mốc tiền: {{ referenceProduct.reference_price | formatCurrency }}
          <a-tooltip
            placement="topLeft"
            v-if="referenceProduct.reference_pb_id"
          >
            <template slot="title">
              Cập nhật giá: <br />
              {{ referenceProduct.updated_at | formatDate }}
            </template>
            <a-icon
              type="question-circle"
              style="transform: translateY(-10px); color: #9c9c9c"
            />
          </a-tooltip>
        </div>
      </div>
      <div class="product-list mb-4 mt-4">
        <div class="text-base font-medium text-black mb-2">
          Danh sách sản phẩm theo dõi
        </div>
        <div
          class="border border-gray-200 rounded pt-1 px-3"
          :class="[
            productUrls.length ? 'pb-3' : 'pb-1',
            isHoverProductUrlInput ? 'border-primary-500' : 'border-gray-200',
          ]"
        >
          <div
            class="enter-product-link-input"
            :style="{
              borderBottom: productUrls.length ? '1px solid #d9d9d9' : '',
            }"
          >
            <a-input-search
              class="search-input product-url-field"
              placeholder="Nhập link sản phẩm cần theo dõi"
              @search="onAddProductUrl"
              v-model="productUrlInputValue"
              @focus="isHoverProductUrlInput = true"
              @blur="isHoverProductUrlInput = false"
              :allowClear="true"
            >
              <a-button
                slot="enterButton"
                type="primary"
                :disabled="
                  disableAddProductUrl || productUrlInputValue.length === 0
                "
                class="add-product-url-button"
              >
                OK
              </a-button>
            </a-input-search>
          </div>
          <div class="product-url-list-items">
            <div
              v-for="({ url, error }, index) in productUrls"
              :key="url"
              class="product-url-list-item"
            >
              <div class="flex flex-col">
                <span class="product-url-list-item-text" :title="url">
                  {{ index + 1 }}. {{ url }}
                </span>
                <span v-if="error" class="text-red-500" style="font-size: 13px">
                  <a-icon type="exclamation-circle" />
                  {{ error }}
                </span>
              </div>
              <div class="close-button" @click="onDeleteProductUrl(url)">
                <svg
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_4192_5174)">
                    <path
                      d="M9.31479 8L11.578 5.7376C11.6523 5.66327 11.7113 5.57503 11.7515 5.47792C11.7917 5.3808 11.8124 5.27672 11.8124 5.1716C11.8124 5.06648 11.7917 4.9624 11.7515 4.86528C11.7113 4.76817 11.6523 4.67993 11.578 4.6056C11.5037 4.53127 11.4154 4.47231 11.3183 4.43209C11.2212 4.39186 11.1171 4.37116 11.012 4.37116C10.9069 4.37116 10.8028 4.39186 10.7057 4.43209C10.6086 4.47231 10.5203 4.53127 10.446 4.6056L8.18359 6.8688L5.92119 4.6056C5.77108 4.45549 5.56749 4.37116 5.35519 4.37116C5.1429 4.37116 4.93931 4.45549 4.78919 4.6056C4.63908 4.75571 4.55475 4.95931 4.55475 5.1716C4.55475 5.27672 4.57545 5.3808 4.61568 5.47792C4.65591 5.57503 4.71487 5.66327 4.78919 5.7376L7.05239 8L4.78919 10.2624C4.63908 10.4125 4.55475 10.6161 4.55475 10.8284C4.55475 11.0407 4.63908 11.2443 4.78919 11.3944C4.93931 11.5445 5.1429 11.6288 5.35519 11.6288C5.56749 11.6288 5.77108 11.5445 5.92119 11.3944L8.18359 9.1312L10.446 11.3944C10.5961 11.5445 10.7997 11.6288 11.012 11.6288C11.2243 11.6288 11.4279 11.5445 11.578 11.3944C11.7281 11.2443 11.8124 11.0407 11.8124 10.8284C11.8124 10.6161 11.7281 10.4125 11.578 10.2624L9.31479 8ZM8.18359 16C3.76519 16 0.183594 12.4184 0.183594 8C0.183594 3.5816 3.76519 0 8.18359 0C12.602 0 16.1836 3.5816 16.1836 8C16.1836 12.4184 12.602 16 8.18359 16Z"
                      fill="#DADADA"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4192_5174">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0.183594)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="popup-bottom flex justify-end">
        <a-button
          type="primary"
          :disabled="isDisableVerifyButton"
          @click="verifyProductAlert"
          :loading="verifyLoading"
          class="verify-button"
          ghost
        >
          Kiểm tra
        </a-button>
      </div>
      <div v-if="verifiedData.products && verifiedData.products.length">
        <div style="border-bottom: 1px solid #dedede; margin: 16px 0" />
        <div class="text-base font-medium text-black mb-3">
          Mốc cần theo dõi
          <a-button type="primary" ghost class="ml-2">
            {{ verifiedData.reference_price | formatCurrency }}
          </a-button>
        </div>
        <div class="text-base font-medium text-black mb-2">
          Danh sách sản phẩm theo dõi
        </div>
        <table-product-price-alert :products="verifiedData.products" />
        <div class="mt-6">
          <div class="text-base font-medium text-black mb-1">
            Tên theo dõi giá
          </div>
          <a-input
            placeholder="Nhập tên Theo dõi giá"
            size="large"
            @change="onChangePriceAlertName"
            :value="priceAlertName"
            :allowClear="true"
            class="price-alert-name-input"
          />
          <div
            v-if="errorPriceAlertName"
            class="text-red-500 mt-2"
            style="font-size: 13px"
          >
            <a-icon type="exclamation-circle" />
            {{ errorPriceAlertName }}
          </div>
        </div>
        <div class="flex justify-end mt-3">
          <a-button class="mr-2" @click="onClickCancel">Hủy</a-button>
          <a-button
            type="primary"
            @click="onCreatePriceAlert"
            :loading="createLoading"
            :disabled="isDisableSaveButton"
          >
            {{ editPriceAlert ? "Hoàn tất" : "Tạo" }}
          </a-button>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { formatCurrency } from "@/helper/FormatHelper";
import { mixinStoreGetterGlobal } from "@/mixin/store/MixinGlobal";
import TableProductPriceAlert from "@/views/search/TableProductPriceAlert";
import {
  apiCreatePriceAlert,
  apiUpdatePriceAlert,
  apiVerifyProductBase,
  apiVerifyProductList,
} from "@/api/priceAlert/ApiPriceAlert";
import { getUrlProductDirect } from "@/service/product/UrlProductService";
import { QUOTA_PRODUCT_ALERT } from "@/constant/general/GeneralConstant";

const columnsDesktop = [
  {
    title: "Tên sản phẩm",
    dataIndex: "",
    key: "name",
    width: "60%",
    scopedSlots: { customRender: "product" },
  },
  {
    title: "Nơi bán",
    dataIndex: "market",
    key: "market",
    width: "30%",
    scopedSlots: { customRender: "market" },
  },
  {
    title: "Giá bán",
    dataIndex: "price",
    key: "price",
    scopedSlots: { customRender: "price" },
    width: "30%",
  },
];
const columnsMobile = [
  {
    title: "Tên sản phẩm",
    dataIndex: "",
    key: "name",
    width: "60%",
    scopedSlots: { customRender: "product" },
  },
  {
    title: "Giá bán",
    dataIndex: "price",
    key: "price",
    scopedSlots: { customRender: "price" },
    width: "40%",
  },
];

const ERROR_ADD_PRODUCT_URL_MESSAGES = {
  QUOTA_PRODUCT_ALERT: "Lỗi: Vượt quá số lượng được theo dõi của mỗi tài khoản",
  PRODUCT_NOT_FOUND: "Lỗi: Link sản phẩm không tồn tại",
};

export default {
  name: "PopupCreateEditPriceAlert",
  props: {
    cancelText: {
      default: "Huỷ",
    },
    okText: {
      default: "Tiếp tục",
    },
    visible: {
      type: Boolean,
      default: false,
    },
    editPriceAlert: {
      type: Object,
      default: null,
    },
    totalProduct: {
      type: Number,
      default: 0,
    },
  },
  components: { TableProductPriceAlert },
  data() {
    return {
      referenceProduct: {
        inputValue: "",
        reference_price: null,
        reference_pb_name: null,
        reference_pb_id: null,
        updated_at: null,
        error: null,
      },
      reference_price: null,
      productUrls: [],
      verifiedData: {
        products: [],
        reference_price: null,
      },
      priceAlertName: "",
      errorPriceAlertName: null,
      productUrlInputValue: "",
      loading: false,
      verifyLoading: false,
      createLoading: false,
      isHoverProductUrlInput: false,
      columnsDesktop,
      columnsMobile,
    };
  },
  watch: {
    visible(visible) {
      if (visible) {
        this.initData();
      }
    },
  },
  filters: {
    formatCurrency,
    formatDate(value) {
      let date = new Date(value);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const hour = date.getHours();
      const minute =
        date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
      const second =
        date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();
      return `${day}/${month}/${year} ${hour}:${minute}:${second}`;
    },
  },
  methods: {
    initData() {
      if (this.editPriceAlert) {
        const reference_price =
          this.editPriceAlert.reference_price || this.editPriceAlert.price;
        const { reference_pb_id, updated_at, name } = this.editPriceAlert;
        const inputValue = this.editPriceAlert.reference_pb_id
          ? getUrlProductDirect(this.editPriceAlert.reference_pb_id)
          : reference_price;

        this.referenceProduct = {
          inputValue,
          reference_price,
          reference_pb_id,
          reference_pb_name: name,
          updated_at,
        };
        this.productUrls = this.editPriceAlert.lst_product.map((product) => ({
          url: product.product_url,
        }));
        this.verifiedData = {
          products: this.editPriceAlert.lst_product,
          reference_pb_id,
          reference_price,
        };
        this.priceAlertName = this.editPriceAlert.name;
      } else {
        this.referenceProduct = {
          inputValue: "",
          reference_price: null,
          reference_pb_name: null,
          reference_pb_id: null,
          updated_at: null,
          error: null,
        };
        this.verifiedData = { products: [], reference_price: null };
        this.productUrls = [];
        this.priceAlertName = "";
      }
    },
    onClickCancel() {
      this.$emit("cancel");
    },
    async onVerifyBaseProduct(value, event) {
      if (!value) {
        this.referenceProduct = initialReferenceProduct;
        return;
      }
      try {
        this.referenceProduct = {
          ...this.referenceProduct,
          error: null,
        };
        this.loading = true;
        const { price, product_base_id, updated_at, name } =
          await apiVerifyProductBase(value);
        this.loading = false;
        if (isNaN(price) || price === null) {
          this.referenceProduct = {
            ...this.referenceProduct,
            error: "Lỗi. Vui lòng thử lại",
          };
          return;
        }

        this.referenceProduct = {
          ...this.referenceProduct,
          reference_price: price,
          reference_pb_id: product_base_id,
          reference_pb_name: name,
          updated_at,
        };
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    onAddProductUrl(url) {
      if (this.disableAddProductUrl) {
        return;
      }
      const urlExist = this.productUrls.find(
        (productUrl) => productUrl.url === url
      );
      if (urlExist || url.trim() === "") {
        this.productUrlInputValue = "";
        return;
      }
      const productUrl = {
        url,
      };
      if (this.hitQuotaLimitProduct) {
        productUrl.error = ERROR_ADD_PRODUCT_URL_MESSAGES.QUOTA_PRODUCT_ALERT;
      }
      this.productUrls.push(productUrl);
      this.productUrlInputValue = "";
    },
    onChangeReferenceProduct(e) {
      this.referenceProduct = {
        ...this.referenceProduct,
        inputValue: e.target.value,
        reference_pb_id: null,
      };
    },
    onDeleteProductUrl(url) {
      this.productUrls = this.productUrls
        .filter((item) => item.url !== url)
        .map((item) => ({
          ...item,
          error:
            item.error === ERROR_ADD_PRODUCT_URL_MESSAGES.QUOTA_PRODUCT_ALERT
              ? null
              : item.error,
        }));
    },
    async verifyProductAlert() {
      try {
        this.verifyLoading = true;
        this.productUrls = this.productUrls.map(({ url }) => ({ url }));
        const verifiedProducts = await apiVerifyProductList(
          this.productUrls.map(({ url }) => url)
        );

        this.verifyLoading = false;
        this.productUrls = this.productUrls.map((item) => ({
          url: item.url,
          error: verifiedProducts
            .map((item) => item.product_url)
            .includes(item.url)
            ? null
            : ERROR_ADD_PRODUCT_URL_MESSAGES.PRODUCT_NOT_FOUND,
        }));

        this.verifiedData = {
          ...this.referenceProduct,
          products: verifiedProducts,
        };
        this.priceAlertName = this.verifiedData.reference_pb_name || "";
      } catch (error) {
        console.log(error);
        this.verifyLoading = false;
      }
    },
    async onCreatePriceAlert() {
      if (!this.priceAlertName) {
        this.errorPriceAlertName = "Vui lòng tạo tên Theo dõi giá";
        return;
      }
      const lst_product_base_id = this.verifiedData.products.map(
        (item) => item.product_base_id
      );
      const newPriceAlert = {
        lst_product_base_id,
        name: this.priceAlertName,
      };
      if (this.verifiedData.reference_pb_id) {
        newPriceAlert.reference_pb_id = this.verifiedData.reference_pb_id;
      } else {
        newPriceAlert.reference_price = this.verifiedData.reference_price;
      }
      try {
        this.createLoading = true;
        if (this.editPriceAlert) {
          await apiUpdatePriceAlert({
            ...newPriceAlert,
            id: this.editPriceAlert.price_alert_id,
          });
          this.$message.success("Sửa thông tin theo dõi giá thành công");
        } else {
          await apiCreatePriceAlert(newPriceAlert);
          this.$message.success("Tạo theo dõi giá thành công");
        }
        this.$emit("created");
        this.createLoading = false;
      } catch (error) {
        this.createLoading = false;
        console.log(error);
        if (this.editPriceAlert) {
          this.$message.error("Sửa thông tin theo dõi giá thất bại");
        } else {
          this.$message.error("Tạo theo dõi giá thất bại");
        }
      }
    },
    onChangePriceAlertName(e) {
      this.errorPriceAlertName = null;
      this.priceAlertName = e.target.value;
    },
  },
  computed: {
    hitQuotaLimitProduct() {
      return this.productUrls.length + this.totalProduct >= QUOTA_PRODUCT_ALERT;
    },
    disableAddProductUrl() {
      return (
        this.productUrls.length + this.totalProduct >= QUOTA_PRODUCT_ALERT + 1
      );
    },
    isDisableVerifyButton() {
      const isReferencePriceDirty = isNaN(this.referenceProduct.inputValue)
        ? !this.referenceProduct.reference_pb_id
        : Number(this.referenceProduct.inputValue) !==
          Number(this.referenceProduct.reference_price);

      return (
        !this.referenceProduct.reference_price ||
        isNaN(this.referenceProduct.reference_price) ||
        !this.productUrls.length ||
        isReferencePriceDirty ||
        this.disableAddProductUrl
      );
    },
    isDisableSaveButton() {
      const isNotVerifyReferencePrice =
        Number(this.verifiedData.reference_price) !==
        Number(this.referenceProduct.reference_price);

      let isAllProductUrlVerified = false;
      const notErrorProductUrls = this.productUrls.filter(
        (item) => item.status !== "error"
      );
      if (notErrorProductUrls.length >= this.verifiedData.products.length) {
        isAllProductUrlVerified = notErrorProductUrls.every((item) =>
          this.verifiedData.products.find(
            (product) => product.product_url === item.url
          )
        );
      } else {
        isAllProductUrlVerified = this.verifiedData.products.every((item) =>
          notErrorProductUrls.find(
            (product) => product.url === item.product_url
          )
        );
      }

      return (
        this.isDisableVerifyButton ||
        !this.verifiedData.products.length ||
        isNotVerifyReferencePrice ||
        !isAllProductUrlVerified
      );
    },
  },
  mixins: [mixinStoreGetterGlobal],
};
</script>

<style lang="scss" scoped>
.add-product-url-button {
  width: 43px;
  height: 24px;
  padding: 0;
  text-align: center;
}

.add-product-url-button[disabled] {
  background: rgba(163, 163, 163, 0.8);
  border-color: rgba(163, 163, 163, 0.8);
  color: #fff;
}

.verify-button {
  font-weight: 500;
}

.verify-button[disabled] {
  color: #a3a3a3;
  border-color: #a3a3a3;
}
</style>

<style lang="scss">
.create-price-alert-popup {
  .ant-input {
    &:focus,
    &:active {
      box-shadow: none;
    }

    &::-webkit-input-placeholder {
      font-style: italic;
    }

    &:-moz-placeholder {
      font-style: italic;
    }

    &::-moz-placeholder {
      font-style: italic;
    }

    &:-ms-input-placeholder {
      font-style: italic;
    }
  }

  .add-product-input {
    .search-input {
      width: 100%;
      .ant-input,
      .ant-input-search-button {
        height: 40px;
      }
    }
  }

  .product-list {
    .enter-product-link-input {
      .ant-input {
        border-radius: 0;
        height: 40px;
        padding-left: 0;
        border: 0;

        &:focus,
        &:active {
          outline: none;
        }
      }

      .ant-input-search-button {
        border-radius: 2px !important;
      }
    }
  }

  .product-url-list-items {
    .product-url-list-item {
      border-top: 1px solid #e8e8e8;
      padding-bottom: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 12px;
      color: #8c8c8c;

      .product-url-list-item-text {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 250px;
      }

      .close-button {
        cursor: pointer;
        display: flex;
        align-items: center;

        svg {
          width: 16px;
          height: 16px;
          fill: #d9d9d9;
        }
      }
    }
  }

  .product-table {
    background: #ffffff;
    border: 1px solid #f4f4f4;
    box-sizing: border-box;
    box-shadow: 0px 4px 6px rgba(171, 171, 171, 0.25);
    border-radius: 4px;
  }

  .shopee-market-icon {
    background: url("https://lh3.googleusercontent.com/mvI9jsrKjP7Q7ZqYOXuJTRZF9Q1e4PZJjjmNAyzh6OKtxtSUNPK920MMFsXploU60VPMqHCdJLEMzaEF_ktvIXuII2IEamnw0Cr5Cb97_803ePqQQYOLtHQLrVUe9e2DPZ3MoxTp")
      0px center / contain no-repeat;
    width: 20px;
    height: 20px;
    top: 0px;
    padding-right: 0px;
    border-radius: 50%;
  }

  .price-alert-name-input input {
    font-size: 12px;
  }

  @media (min-width: 576px) {
    .price-alert-name-input input {
      font-size: 14px;
    }
  }
}
</style>
