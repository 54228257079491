<template>
  <div class="table-price-alert">
    <a-table
      :columns="columns"
      :row-key="(record) => record.id"
      :data-source="data.listPriceAlert"
      :pagination="data.pagination"
      :loading="loading"
      @change="(pagination, filters, sorter) => $emit('change', pagination, filters, sorter)"
    >
      <div slot="name" slot-scope="item" class="flex">
        <div class="mr-2" style="display: flex; align-items: center">
          <img
            :src="item.url_thumbnail"
            style="width: 52px; border-radius: 2px;"
            class="mt-1"
            v-if="item.url_thumbnail"
          />
          <svg v-else width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.25" y="0.25" width="51.5" height="51.5" rx="1.75" fill="white"/>
            <path d="M35.8308 35.4673H16.1687C14.4253 35.4673 13 34.1051 13 32.439V19.0282C13 17.3622 14.4253 16 16.1687 16H35.8308C37.5742 16 38.9995 17.3622 38.9995 19.0282V32.4368C38.9995 34.1051 37.5742 35.4673 35.8308 35.4673Z" fill="#CFCFCF"/>
            <path d="M32.6617 25.422C32.3977 25.422 32.1833 25.2163 32.1833 24.9629C32.1833 23.2045 31.4702 21.5519 30.1753 20.3084C29.9884 20.129 29.9884 19.8383 30.1753 19.6589C30.3622 19.4796 30.665 19.4796 30.8522 19.6589C32.3274 21.0755 33.1401 22.9596 33.1401 24.9629C33.1401 25.2163 32.9257 25.422 32.6617 25.422Z" fill="white"/>
            <path d="M19.5868 25.4234C19.3228 25.4234 19.1084 25.2177 19.1084 24.9642C19.1084 22.961 19.921 21.0769 21.3969 19.6609C21.5837 19.4815 21.8869 19.4815 22.0738 19.6609C22.2606 19.8403 22.2606 20.1313 22.0738 20.3106C20.7782 21.5533 20.0652 23.2058 20.0652 24.9642C20.0652 25.2177 19.8508 25.4234 19.5868 25.4234Z" fill="white"/>
            <path d="M26.1243 33.5354C24.8052 33.5354 23.7324 32.5057 23.7324 31.2395C23.7324 30.986 23.9468 30.7803 24.2108 30.7803C24.4748 30.7803 24.6892 30.986 24.6892 31.2395C24.6892 31.9994 25.3326 32.617 26.1243 32.617C26.9157 32.617 27.5595 31.9994 27.5595 31.2395C27.5595 30.986 27.7738 30.7803 28.0379 30.7803C28.3019 30.7803 28.5162 30.986 28.5162 31.2395C28.5162 32.5057 27.4435 33.5354 26.1243 33.5354Z" fill="white"/>
            <path d="M31.3879 31.6983H20.8635C20.2479 31.6983 19.7471 31.2176 19.7471 30.6269C19.7471 30.3132 19.8894 30.0165 20.1375 29.8124C21.1078 29.0258 21.6606 27.8821 21.6606 26.6704V24.9634C21.6606 22.6003 23.6635 20.6777 26.1257 20.6777C28.5876 20.6777 30.5905 22.6003 30.5905 24.9634V26.6704C30.5905 27.8821 31.1436 29.0258 32.1073 29.8081C32.3617 30.0162 32.504 30.3132 32.504 30.6266C32.504 31.2176 32.0032 31.6983 31.3879 31.6983ZM26.1257 21.5961C24.1909 21.5961 22.6174 23.1066 22.6174 24.9634V26.6704C22.6174 28.1525 21.9407 29.5521 20.7612 30.5093C20.7388 30.5276 20.7038 30.5655 20.7038 30.6269C20.7038 30.7101 20.7765 30.7799 20.8635 30.7799H31.3879C31.4746 30.7799 31.5472 30.7101 31.5472 30.6269C31.5472 30.5658 31.5123 30.5276 31.491 30.5107C30.3103 29.5524 29.6337 28.1528 29.6337 26.6704V24.9634C29.6337 23.1066 28.0601 21.5961 26.1257 21.5961Z" fill="white"/>
            <path d="M26.1249 21.596C25.8609 21.596 25.6465 21.3902 25.6465 21.1368V19.3C25.6465 19.0466 25.8609 18.8408 26.1249 18.8408C26.3889 18.8408 26.6032 19.0466 26.6032 19.3V21.1368C26.6032 21.3902 26.3889 21.596 26.1249 21.596Z" fill="white"/>
            <rect x="0.25" y="0.25" width="51.5" height="51.5" rx="1.75" stroke="#CFCFCF" stroke-width="0.5"/>
          </svg>
        </div>
        <p
          :title="item.name"
          class="text-sm text-black font-medium line-clamp__3"
        >
          {{ item.name }}
        </p>
      </div>
      <div
        slot="reference_price"
        slot-scope="item"
        class="font-medium"
        :class="{ disabled: item.disabled }"
      >
        <div class="text-black mb-4">
          {{ item.reference_price | formatCurrency }}
          <a-tooltip placement="topLeft" v-if="item.reference_pb_id">
            <template slot="title">
              Cập nhật giá: <br />
              {{ item.updated_at | formatDate }}
            </template>
            <a-icon
              type="question-circle"
              style="transform: translateY(-6px); color: #9c9c9c"
            />
          </a-tooltip>
        </div>
        <a-tooltip placement="topLeft" v-if="isMobile">
          <template slot="title">
            {{ item.name }}
          </template>
          <p
            :title="item.name"
            style="color: #9c9c9c"
            class="text-xs font-normal line-clamp__2"
          >
            {{ item.name }}
          </p>
        </a-tooltip>
      </div>
      <div
        slot="lst_product"
        slot-scope="item"
        :class="{ disabled: item.disabled }"
      >
        <div class="mb-2">
          <span v-if="isDesktop">
            Danh sách {{ item.lst_product.length }} sản phẩm.
          </span>
          <span v-else>Danh sách sp</span>
          <a class="link underline" @click="$emit('detail', item)"> Xem </a>
        </div>
        <div>
          <div>
            <span class="text-success">
              Giá thấp nhất
              {{ item.lowestPriceProduct.price | formatCurrency }}
              <a-tooltip placement="topLeft">
                <template slot="title">
                  Cập nhật giá: <br />
                  {{ item.lowestPriceProduct.updated_at | formatDate }}
                </template>
                <a-icon
                  type="question-circle"
                  style="transform: translateY(-6px); color: #9c9c9c"
                />
              </a-tooltip>
            </span>
          </div>
        </div>
        <div
          class="text-red-500 mt-1"
          style="font-size: 13px"
          v-if="item.disabled"
        >
          <a-icon type="exclamation-circle" />
          Danh sách sản phẩm không còn tồn tại
        </div>
      </div>
      <div
        slot="delta_percent"
        slot-scope="item"
        :class="{ disabled: item.disabled }"
      >
        <div>
          <a-icon type="arrow-up" v-if="item.delta_percent > 0" />
          <a-icon type="arrow-down" v-if="item.delta_percent < 0" />
          {{
            item.delta_percent !== 0 ? Math.abs(item.delta_percent) + "%" : 0
          }}
        </div>
      </div>
      <div
        slot="delta_price"
        slot-scope="item"
        :class="[
          item.delta_price === 0
            ? 'text-dark'
            : item.delta_price > 0
            ? 'text-success'
            : 'text-danger',
          { disabled: item.disabled },
        ]"
      >
        {{ item.delta_price | formatDiffPrice }}
      </div>
      <div
        slot="action"
        slot-scope="item"
        href="javascript:;"
        class="flex items-center justify-center"
      >
        <a-button
          type="blue"
          class="flex items-center mr-2 edit-button"
          @click="$emit('edit', item)"
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            :class="isDesktop ? 'mr-2' : ''"
          >
            <path
              d="M11.2958 3.1272L12.874 4.70469L11.2958 3.1272ZM12.3106 1.7375L8.04317 6.00498C7.82267 6.22517 7.67229 6.5057 7.61098 6.81123L7.2168 8.78439L9.18996 8.38946C9.49547 8.32836 9.77565 8.17858 9.99621 7.95802L14.2637 3.69054C14.3919 3.5623 14.4937 3.41006 14.5631 3.2425C14.6325 3.07495 14.6682 2.89537 14.6682 2.71402C14.6682 2.53266 14.6325 2.35308 14.5631 2.18553C14.4937 2.01798 14.3919 1.86573 14.2637 1.7375C14.1355 1.60926 13.9832 1.50753 13.8157 1.43813C13.6481 1.36873 13.4685 1.33301 13.2872 1.33301C13.1058 1.33301 12.9262 1.36873 12.7587 1.43813C12.5911 1.50753 12.4389 1.60926 12.3106 1.7375V1.7375Z"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M13.1773 10.2748V12.5102C13.1773 12.9055 13.0203 13.2845 12.7408 13.564C12.4613 13.8435 12.0822 14.0005 11.687 14.0005H3.4903C3.09505 14.0005 2.71599 13.8435 2.4365 13.564C2.15701 13.2845 2 12.9055 2 12.5102V4.31354C2 3.91829 2.15701 3.53923 2.4365 3.25974C2.71599 2.98026 3.09505 2.82324 3.4903 2.82324H5.72575"
              stroke="currentColor"
              stroke-width="1.25"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span v-if="isDesktop">Sửa</span>
        </a-button>
        <a-button
          type="danger"
          class="flex items-center delete-button"
          ghost
          @click="$emit('delete', item)"
        >
          <IconDelete />
          <span v-if="isDesktop" :class="isDesktop ? 'ml-2' : ''">Xóa</span>
        </a-button>
      </div>
    </a-table>
  </div>
</template>

<script>
import IconDelete from "@/assets/icon/IconDelete";
import { formatCurrency, formatNumberHuman } from "@/helper/FormatHelper";
import PopupConfirm from "@/views/popup/PopupConfirm";
import PopupFollowingProducts from "@/views/popup/PopupDetailPriceAlert";
import { mixinStoreGetterGlobal } from "@/mixin/store/MixinGlobal";

const columnsDesktop = [
  {
    title: "Tên theo dõi giá",
    dataIndex: "",
    scopedSlots: { customRender: "name" },
    width: "20%",
  },
  {
    title: "Mốc cần theo dõi",
    dataIndex: "",
    scopedSlots: { customRender: "reference_price" },
    width: "16%",
    align: "center",
  },
  {
    title: "Sản phẩm theo dõi",
    dataIndex: "",
    scopedSlots: { customRender: "lst_product" },
    width: "23%",
  },
  {
    title: "Phần trăm lệch mốc theo dõi (%)",
    dataIndex: "",
    key: "delta_percent",
    scopedSlots: { customRender: "delta_percent" },
    width: "18%",
    align: "center",
  },
  {
    title: "Giá lệch mốc theo dõi",
    dataIndex: "",
    key: "delta_price",
    scopedSlots: { customRender: "delta_price" },
    width: "20%",
    align: "center",
  },
  {
    title: "Thao tác",
    width: "12%",
    dataIndex: "",
    key: "x",
    scopedSlots: { customRender: "action" },
    align: "center",
  },
];

const columnsMobile = [
  {
    title: "Mốc cần theo dõi",
    dataIndex: "",
    scopedSlots: { customRender: "reference_price" },
    width: "23%",
  },
  {
    title: "Sản phẩm theo dõi",
    dataIndex: "",
    scopedSlots: { customRender: "lst_product" },
    width: "23%",
  },
  {
    title: "Phần trăm lệch mốc theo dõi (%)",
    dataIndex: "",
    key: "delta_percent",
    scopedSlots: { customRender: "delta_percent" },
    width: "20%",
    align: "center",
  },
  {
    title: "Giá lệch mốc theo dõi",
    dataIndex: "",
    key: "delta_price",
    scopedSlots: { customRender: "delta_price" },
    width: "20%",
    align: "center",
  },
  {
    title: "Thao tác",
    width: "14%",
    dataIndex: "",
    key: "x",
    scopedSlots: { customRender: "action" },
    align: "center",
  },
];

export default {
  components: {
    IconDelete,
    PopupConfirm,
    PopupFollowingProducts,
  },
  props: {
    sortMobile: {
      type: String,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  filters: {
    formatDiffPrice: (price) => {
      if (price === 0) return 0 + "₫";
      if (price > 0) {
        return `+${formatCurrency(price)}`;
      }
      return formatCurrency(price);
    },
    formatCurrency,
    formatNumberHuman,
    formatDate(value) {
      let date = new Date(value);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const hour = date.getHours();
      const minute =
        date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
      const second =
        date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();
      return `${day}/${month}/${year} ${hour}:${minute}:${second}`;
    },
  },
  data() {
    return {
      columns: [],
    };
  },
  created() {
    const columns = this.isDesktop ? columnsDesktop : columnsMobile;
    // enable sorter for  "percent" and "delta_price" on desktop
    this.columns = columns.map((col) => ({
      ...col,
      sorter:
        this.isDesktop && ["delta_percent", "delta_price"].includes(col.key),
    }));
  },
  mixins: [mixinStoreGetterGlobal],
};
</script>

<style lang="scss" scoped>
::v-deep .ant-table-thead {
  height: 64px;
}

.text-danger {
  color: #f50000;
}

.text-success {
  color: #00c259;
}

.ant-btn-blue {
  background-color: #3b82f6;
  color: #fff;
  border-color: #3b82f6;

  &:hover {
    background-color: #639cf8;
  }
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 6px;
}

.edit-button,
.delete-button {
  background-color: #f1f1f1 !important;
  border-color: #f1f1f1;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-button {
  color: #3b82f6;
}

.delete-button {
  color: #ef4444;
}

.disabled {
  color: #9e9e9e;

  .text-success {
    color: #9e9e9e;
  }
}

@media (min-width: 640px) {
  .edit-button,
  .delete-button {
    color: #fff;
    border-radius: 4px;
    width: unset;
    height: 32px;
    padding: 0 15px;
  }
  .edit-button {
    background-color: #3b82f6 !important;
    border-color: #3b82f6;
    color: #fff;
  }
  .delete-button {
    color: #ef4444;
    border-color: #ef4444;
    background-color: #ffffff !important;
  }
}
</style>

<style lang="scss">
.ant-table {
  font-size: 12px;
}

.table-price-alert {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 6px;
  }
}

@media (min-width: 640px) {
  .ant-table {
    font-size: 14px;
  }
  .table-price-alert {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      padding: 16px;
    }
    .ant-table-thead > tr > th {
      background-color: #f2f2f2;
    }
  }
}
</style>
