<template>

  <!--  <div class="container-bg-white container-space-default border-solid">-->
  <div class="">
    <a-tabs v-model="tabName" size="large" type="card">
      <a-tab-pane key="history_price">
      <span slot="tab">
        <a-icon type="line-chart"/>
        Biến động giá
      </span>
<!--        <h2 class="title">-->
<!--          <a-icon type="line-chart"/>-->
<!--          Biến động giá-->
<!--        </h2>-->
        <a-spin :spinning="!productHistoryPrice">
          <ProductPriceChart class="" v-if="productHistoryPrice"
                             :product-history-price="productHistoryPrice"
                             :price-current="priceCurrent"/>
        </a-spin>
      </a-tab-pane>
      <a-tab-pane key="history_order" forceRender>
      <span slot="tab">
        <a-icon type="rise"/>
          Lịch sử đã bán
      </span>
<!--        <h2 class="title">-->
<!--          <a-icon type="rise"/>-->
<!--          Lịch sử đã bán-->
<!--        </h2>-->
        <ProductOrderCountChart v-if="productHistoryOrder"
                                :productHistoryOrder="productHistoryOrder"/>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
  // import Default from '@/layout/Default'
  import ProductPriceChart from '@/components/product/ProductPriceChart'
  import { apiGetHistoryOrder, apiGetHistoryPrice } from '@/api/product/ApiProductHistory'
  import ProductOrderCountChart from '@/components/product/ProductOrderCountChart'

  export default {
    components: {
      // Default,
      ProductPriceChart,
      ProductOrderCountChart
    },
    props: {
      productBaseId: null,
      popupProductHistoryTab: null
    },
    data() {
      return {
        productHistoryPrice: null,
        productHistoryOrder: null,
        priceCurrent: null,
        tabName: this.popupProductHistoryTab
      }
    },
    async mounted() {
      this.fetchHistory()
    },
    methods: {
      async fetchHistory() {
        // console.log('mounted pooup this.productBaseId ', this.productBaseId, this.popupProductHistoryTab)
        // let productBaseId = '1__2183565860__143507024'
        if (this.productBaseId?.length > 0) {
          apiGetHistoryOrder(this.productBaseId)
            .then(responseHistoryOrder => {
              if (responseHistoryOrder?.['data'] != null) {
                this.productHistoryOrder = responseHistoryOrder['data']
                // console.log('orderHistory', this.productHistoryOrder)
              }
            })
          apiGetHistoryPrice(this.productBaseId)
            .then(responseHistoryPrice => {
              if (responseHistoryPrice?.['data']?.['item_history'] != null) {
                this.productHistoryPrice = responseHistoryPrice['data']['item_history']
              }
            })
        }
      }
    },

    watch: {
      productBaseId() {
        this.productHistoryOrder = null
        this.productHistoryPrice = null
        this.fetchHistory()
      }
    },

  }
</script>
<style scoped lang="scss">

</style>
