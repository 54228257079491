<template>
  <svg
    width="13"
    height="15"
    viewBox="0 0 13 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.75047 5.73926C8.56639 5.73926 8.41699 5.88614 8.41699 6.06713V12.2638C8.41699 12.4448 8.56639 12.5917 8.75047 12.5917C8.93456 12.5917 9.08396 12.4448 9.08396 12.2638V6.06713C9.08396 5.88614 8.93489 5.73926 8.75047 5.73926Z"
      fill="#EF4444"
      stroke="currentColor"
      stroke-width="0.2"
    />
    <path
      d="M4.81493 5.73926C4.63085 5.73926 4.48145 5.88614 4.48145 6.06713V12.2638C4.48145 12.4448 4.63085 12.5917 4.81493 12.5917C4.99901 12.5917 5.14841 12.4448 5.14841 12.2638V6.06713C5.14841 5.88614 4.99935 5.73926 4.81493 5.73926Z"
      fill="#EF4444"
      stroke="currentColor"
      stroke-width="0.2"
    />
    <path
      d="M1.94719 4.83489V12.9129C1.94719 13.3903 2.12526 13.8388 2.4364 14.1604C2.7462 14.4831 3.17705 14.6663 3.62791 14.667H9.9373C10.3885 14.6663 10.8194 14.4831 11.1288 14.1604C11.44 13.8388 11.618 13.3903 11.618 12.9129V4.83489C12.2363 4.67358 12.6371 4.08604 12.5544 3.46244C12.4717 2.83884 11.9312 2.37228 11.2912 2.37195H9.58382V1.96212C9.58582 1.61753 9.44709 1.28638 9.19899 1.0431C8.95088 0.799826 8.61373 0.664416 8.26325 0.667039H5.30197C4.95148 0.664089 4.61434 0.799826 4.36623 1.0431C4.11812 1.28671 3.9794 1.61753 3.9814 1.96212V2.37195H2.27399C1.63405 2.37195 1.09382 2.83851 1.01078 3.46244C0.928079 4.08637 1.32892 4.67358 1.94719 4.83489ZM9.9373 14.0113H3.62791C3.05767 14.0113 2.61414 13.5296 2.61414 12.9129V4.86375H10.9511V12.9129C10.9511 13.5296 10.5076 14.0113 9.9373 14.0113ZM4.64835 1.96212C4.64602 1.7913 4.71438 1.62704 4.83777 1.50671C4.96082 1.38605 5.12823 1.31982 5.3023 1.32277H8.26358C8.43732 1.31982 8.60473 1.38605 8.72812 1.50671C8.8515 1.62704 8.91953 1.79163 8.91753 1.96212V2.37195H4.64902V1.96212H4.64835ZM2.27399 3.02769H11.2912C11.6227 3.02769 11.8915 3.29195 11.8915 3.61785C11.8915 3.94375 11.6227 4.20801 11.2912 4.20801H2.27399C1.94252 4.20801 1.67373 3.94375 1.67373 3.61785C1.67373 3.29195 1.94252 3.02769 2.27399 3.02769Z"
      fill="#EF4444"
      stroke="currentColor"
      stroke-width="0.2"
    />
    <path
      d="M6.7827 5.73926C6.59862 5.73926 6.44922 5.88614 6.44922 6.06713V12.2638C6.44922 12.4448 6.59862 12.5917 6.7827 12.5917C6.96679 12.5917 7.11619 12.4448 7.11619 12.2638V6.06713C7.11619 5.88614 6.96712 5.73926 6.7827 5.73926Z"
      fill="#EF4444"
      stroke="currentColor"
      stroke-width="0.2"
    />
  </svg>
</template>

<script>
export default {
  name: "IconDelete"
}
</script>
