<template>
  <div>
    <Default v-if="isDesktop" class="page-search">
      <div class="container-bg-white container-space-default" style="">
        <div class="relative">
          <h1
            class="text-black pl-2 mb-4 mt-1 title"
            style="font-size: 22px; border-left: 3px solid #ff7227"
          >
            Theo dõi giá
          </h1>
        </div>
        <div class="flex justify-end mb-6">
          <div class="text-right">
            <button
              class="ant-btn ant-btn-primary"
              size="large"
              style="font-size: 16px"
              @click="() => toggleCreateEditPriceAlert()"
              :disabled="hitQuotaLimitProduct"
            >
              + Theo dõi giá mới
            </button>
            <div
              v-if="hitQuotaLimitProduct"
              class="text-red-500 mt-2"
              style="font-size: 13px"
            >
              Bạn đã đạt đến giới hạn <br />
              sản phẩm theo dõi ({{ data.totalProduct }} /
              {{ QUOTA_PRODUCT_ALERT }})
            </div>
          </div>
        </div>
        <TablePriceAlert
          :data="data"
          :loading="loadingPriceAlertTable"
          @edit="toggleCreateEditPriceAlert"
          @delete="(item) => (deletePriceAlert = item)"
          @detail="toggleViewDetailPriceAlert"
          @change="handleTableChange"
          ref="tablePriceAlert"
        />
      </div>
    </Default>
    <MobileDefault v-else-if="isMobile">
      <div class="px-3">
        <div class="container-bg-white">
          <div class="p-3">
            <div
              class="text-xl text-black pl-1 mb-4 mt-1 font-medium"
              style="line-height: 1; border-left: 2px solid #ff7227"
            >
              Theo dõi giá
            </div>
            <div class="flex justify-space-between">
              <button
                class="ant-btn ant-btn-primary"
                @click="() => toggleCreateEditPriceAlert()"
              >
                + Theo dõi giá mới
              </button>
              <button
                class="bg-white"
                :disabled="loadingPriceAlertTable"
                @click="() => toggleShortPopupMobile()"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.73969 9.15793H14.261C14.8972 9.15793 15.2351 8.2986 14.8123 7.75519L10.552 2.28323C10.4829 2.19419 10.398 2.1229 10.303 2.07407C10.208 2.02525 10.1049 2 10.0007 2C9.89652 2 9.7935 2.02525 9.69847 2.07407C9.60344 2.1229 9.51857 2.19419 9.44946 2.28323L5.18769 7.75519C4.76483 8.2986 5.10282 9.15793 5.73969 9.15793ZM9.44873 17.7168C9.51783 17.8058 9.6027 17.8771 9.69773 17.9259C9.79276 17.9748 9.89578 18 9.99999 18C10.1042 18 10.2072 17.9748 10.3022 17.9259C10.3973 17.8771 10.4821 17.8058 10.5513 17.7168L14.8115 12.2448C15.2351 11.7022 14.8972 10.8429 14.2603 10.8429H5.73969C5.10356 10.8429 4.76557 11.7022 5.18843 12.2457L9.44873 17.7168Z"
                    fill="#BDBDBD"
                  />
                </svg>
              </button>
            </div>
          </div>
          <TablePriceAlert
            :data="data"
            :loading="loadingPriceAlertTable"
            @edit="toggleCreateEditPriceAlert"
            @delete="(item) => (deletePriceAlert = item)"
            @detail="toggleViewDetailPriceAlert"
            @change="handleTableChange"
            ref="tablePriceAlert"
          />
        </div>
      </div>
      <PopupSortPriceAlertMobile
        :visible="showSortPriceAlertPopup"
        @submit="toggleShortPopupMobile"
        @cancel="toggleShortPopupMobile"
      />
    </MobileDefault>
    <PopupCreateEditPriceAlert
      :visible="showCreatePriceAlertPopup"
      :totalProduct="data.totalProduct"
      @cancel="toggleCreateEditPriceAlert"
      @created="onCreactPriceAlertSuccess"
      :editPriceAlert="editPriceAlert"
    />
    <PopupConfirm
      ok-text="Đồng ý"
      text="Bạn muốn bỏ Theo dõi giá?"
      :description="getDeletePriceAlertDescription"
      :is-shown-popup="!!deletePriceAlert"
      :loading="loadingDeletePriceAlert"
      @cancel="deletePriceAlert = null"
      @ok="onDeletePriceAlert(deletePriceAlert)"
    />
    <PopupDetailPriceAlert
      :isDesktop="isDesktop"
      :visible="showViewDetailPriceAlertPopup"
      :viewDetailPriceAlert="viewDetailPriceAlert"
      @cancel="showViewDetailPriceAlertPopup = false"
    />
  </div>
</template>

<script>
import Default from "@/layout/Default";
import MobileDefault from "@/layout/MobileDefault";
import TablePriceAlert from "@/views/search/TablePriceAlert";
import { mixinStoreGetterGlobal } from "@/mixin/store/MixinGlobal";
import PopupCreateEditPriceAlert from "@/views/popup/PopupCreateEditPriceAlert";
import PopupSortPriceAlertMobile from "@/views/popup/PopupSortPriceAlertMobile";
import { ActionNameConstants } from "@/constant/general/GeneralConstant";
import { MutationSidebar } from "@/store/modules/SidebarModule/mutation";
import { MutationGeneral } from "@/store/modules/GeneralModule/mutation";
import {
  mixinStoreGetterUser,
  mixinStoreGetterPayment,
  mixinStoreGetterPermission,
} from "@/mixin/store/MixinUser";
import PopupConfirm from "@/views/popup/PopupConfirm";
import PopupDetailPriceAlert from "@/views/popup/PopupDetailPriceAlert";
import { formatCurrency } from "@/helper/FormatHelper";
import { PERMISSION } from "@/constant/general/PermissionConstant";
import {
  apiDeletePriceAlert,
  apiGetPriceAlertList,
} from "@/api/priceAlert/ApiPriceAlert";
import { getUrlProductDirect } from "@/service/product/UrlProductService";
import { QUOTA_PRODUCT_ALERT } from "@/constant/general/GeneralConstant";

export default {
  name: "PriceAlertView",
  components: {
    Default,
    MobileDefault,
    TablePriceAlert,
    PopupCreateEditPriceAlert,
    PopupSortPriceAlertMobile,
    PopupConfirm,
    PopupDetailPriceAlert,
  },
  mixins: [
    mixinStoreGetterGlobal,
    mixinStoreGetterUser,
    mixinStoreGetterPermission,
    mixinStoreGetterPayment,
  ],
  data() {
    return {
      data: {
        listPriceAlert: [],
        total: 0,
        totalProduct: 0,
        pagination: {
          simple: true,
          pageSize: 20,
          total: 0,
          current: 1,
          order_by: "id__desc",
        },
      },
      loadingPriceAlertTable: false,
      showCreatePriceAlertPopup: false,
      showSortPriceAlertPopup: false,
      editPriceAlert: null,
      deletePriceAlert: null,
      showPopupDeletePriceAlert: false,
      showViewDetailPriceAlertPopup: false,
      loadingDeletePriceAlert: false,
      viewDetailPriceAlert: null,
      QUOTA_PRODUCT_ALERT,
    };
  },
  created() {
    // update state for UI
    // Cập nhật trạng thái {menu, actionName} đang ở màn hình search
    this.$store.commit(
      `GeneralModule/${MutationGeneral.setActionName}`,
      ActionNameConstants.PRICE_ALERT
    );
    this.$store.commit(
      `SidebarModule/${MutationSidebar.menuSelected}`,
      ActionNameConstants.PRICE_ALERT
    );
  },
  mounted() {
    this.fetchPriceAlertTable();
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      if (this.isMobile) {
        pagination.order_by = this.data.pagination.order_by;
      } else {
        const { columnKey, order } = sorter;
        if (columnKey && order) {
          pagination.order_by = `${columnKey}__${
            order === "descend" ? "desc" : "asc"
          }`;
        } else {
          pagination.order_by = "id__desc";
        }
      }
      this.fetchPriceAlertTable(pagination);
    },
    async fetchPriceAlertTable(pagination = {}) {
      const { pageSize, current, order_by } = {
        ...this.data.pagination,
        ...pagination,
      };
      const params = {
        limit: pageSize,
        page: current - 1,
        order_by,
      };
      if (pagination && pagination.order_by !== this.data.pagination.order_by) {
        params.page = 0;
      }
      this.loadingPriceAlertTable = true;
      try {
        const { lst_price_alert_detail, total_item, total_product } =
          await apiGetPriceAlertList(params);
        this.loadingPriceAlertTable = false;
        const listPriceAlert = lst_price_alert_detail.map((item) => {
          const lowestPrice = item.lst_product.reduce(
            (lowestPrice, product) => {
              return product.price < lowestPrice ? product.price : lowestPrice;
            },
            Infinity
          );
          const highestPrice = item.lst_product.reduce(
            (highestPrice, product) =>
              product.price > highestPrice ? product.price : highestPrice,
            -Infinity
          );
          const lowestPriceProduct = item.lst_product.find(
            (product) => product.price === lowestPrice
          );
          const reference_price = item.price || item.reference_price;
          const delta_percent =
            Math.round(
              ((lowestPrice - reference_price) / reference_price) * 100 * 100
            ) / 100;

          return {
            ...item,
            lowestPriceProduct,
            lowestPrice,
            highestPrice,
            reference_price,
            disabled: this.isDisabledPriceAlert(item),
            delta_price: lowestPrice - reference_price,
            delta_percent: isNaN(delta_percent) ? 0 : delta_percent,
            lst_product: item.lst_product.map((product, index) => ({
              ...product,
              product_url: getUrlProductDirect(
                product.product_base_id,
                product.name
              ),
              delta_percent:
                Math.round(
                  ((product.price - reference_price) / reference_price) *
                    100 *
                    100
                ) / 100,
              delta_price: product.price - reference_price,
              disabled: product.status !== 1,
            })),
          };
        });
        this.data = {
          listPriceAlert,
          total: total_item,
          totalProduct: total_product,
          pagination: {
            ...this.data.pagination,
            total: total_item,
            current: params.page + 1,
            order_by: params.order_by,
          },
        };
      } catch (err) {
        console.log(err);
        this.loadingPriceAlertTable = false;
      }
    },
    isDisabledPriceAlert(product) {
      const isAllProductNotAvailable = product.lst_product.every(
        (item) => item.status !== 1
      );
      return isAllProductNotAvailable;
    },
    toggleCreateEditPriceAlert(item) {
      this.editPriceAlert = item || null;
      this.showCreatePriceAlertPopup = !this.showCreatePriceAlertPopup;
    },
    onCreactPriceAlertSuccess() {
      this.showCreatePriceAlertPopup = false;
      this.fetchPriceAlertTable({ current: 1, order_by: "id__desc" });
    },
    async onCreateEditPriceAlert(item) {
      this.showCreatePriceAlertPopup = false;
      this.toggleCreateEditPriceAlert();
    },
    toggleViewDetailPriceAlert(item) {
      this.showViewDetailPriceAlertPopup = !this.showViewDetailPriceAlertPopup;
      this.viewDetailPriceAlert = item;
    },
    async onDeletePriceAlert(item) {
      try {
        this.loadingDeletePriceAlert = true;
        await apiDeletePriceAlert(item.price_alert_id);
        this.$message.success("Xóa theo dõi giá thành công");
        this.deletePriceAlert = null;
        this.fetchPriceAlertTable();
        this.loadingDeletePriceAlert = false;
      } catch (e) {
        this.$message.success("Xóa theo dõi giá thất bại");
        this.loadingDeletePriceAlert = false;
        this.deletePriceAlert = null;
      }
    },
    toggleShortPopupMobile(value) {
      if (value) {
        this.fetchPriceAlertTable({
          current: 1,
          order_by: value || "id__desc",
        });
      }
      this.showSortPriceAlertPopup = !this.showSortPriceAlertPopup;
    },
  },
  computed: {
    getDeletePriceAlertDescription() {
      return (
        "Đồng ý bỏ theo dõi mốc giá: " +
        formatCurrency(this.deletePriceAlert?.reference_price)
      );
    },
    hitQuotaLimitProduct() {
      return this.data.totalProduct >= QUOTA_PRODUCT_ALERT;
    },
  },
  watch: {
    permissionAvailableName(value) {
      if (value) {
        if (
          !this.isEqualAndHigherPriorityAccount(
            PERMISSION.marketv2_premium.priority
          )
        ) {
          this.$router.push({ name: "Home" });
        }
      }
    },
  },
};
</script>
