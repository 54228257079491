<template>
  <a-modal
    :visible="visible"
    :closable="true"
    :footer="null"
    :mask-closable="false"
    :width="isDesktop ? 845 : '100%'"
    :bodyStyle="isDesktop ? { padding: '16px' } :  { padding: '16px', height: '540px' }"
    @cancel="onClickCancel"
    class="popup-following-products"
  >
    <div>
      <div class="text-base md:text-lg text-primary-500 mb-3 md:mb-4 font-medium">
        Danh sách {{ viewDetailPriceAlert && viewDetailPriceAlert.lst_product.length }} sản phẩm theo dõi
      </div>
      <div class="block md:flex justify-space-between items-center mb-4">
        <div class="block md:flex">
          <div class="mb-2 md:mb-3 mr-4">
            Giá thấp nhất: <span style="color: #00c259">{{ viewDetailPriceAlert && viewDetailPriceAlert.lowestPrice | formatCurrency }}</span>
          </div>
          <div class="mb-2 md:mb-3">
            Giá cao nhất: <span style="color: #F50000">{{ viewDetailPriceAlert && viewDetailPriceAlert.highestPrice | formatCurrency }}</span>
          </div>
        </div>
        <div class="mb-2 md:mb-3" style="color: #2E2E2E">
          Mốc theo dõi: <span class="md:text-lg font-medium">{{ viewDetailPriceAlert && viewDetailPriceAlert.reference_price | formatCurrency }}</span>
        </div>
      </div>
      <table-product-price-alert :products="viewDetailPriceAlert && viewDetailPriceAlert.lst_product"/>
    </div>
  </a-modal>
</template>

<script>
import {formatCurrency} from "@/helper/FormatHelper";
import TableProductPriceAlert from "@/views/search/TableProductPriceAlert";

export default {
  name: "PopupDetailPriceAlert",
  filters: {
    formatCurrency,
  },
  props: {
    cancelText: {
      type: String,
      default: "Huỷ",
    },
    okText: {
      type: String,
      default: "Tiếp tục",
    },
    visible: {
      type: Boolean,
      default: false,
    },
    viewDetailPriceAlert: {
      type: Object,
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
  },
  components: {TableProductPriceAlert},
  methods: {
    onClickOk() {
      this.$emit("ok");
    },
    onClickCancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="scss" scoped>
.text-danger {
  color: #F50000;
}

.text-success {
  color: #00c259;
}

.ant-table {
  background: #ffffff;
  box-shadow: 0px 4px 6px rgba(171, 171, 171, 0.25);
  border-radius: 4px;

  .product-name {
    @apply text-sm font-medium text-black;
  }
}
</style>

<style lang="scss">
.popup-following-products {
  margin: 0;
  max-width: 100%;
  bottom: 0;
  top: unset;
  padding: 0;
  position: absolute;
}

@media (min-width: 576px) {
  .popup-following-products {
    max-width: calc(100vw - 16px);
    margin: 8px auto;
    bottom: unset;
    top: 100px;
    padding: 0;
    position: relative;
  }
}
</style>
