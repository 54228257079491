import { urlApiProductHistoryPrice, urlApiProductOrderPrice } from '@/api/product/ProductEndpoint'
import { http } from '@/plugin/http'

const apiGetHistoryPrice = async (productBaseId) => {
  const urlApi = urlApiProductHistoryPrice(productBaseId)
  try {
    const response = await http.get(urlApi)
    if (response?.data != null) {
      return response.data
    }

  } catch (e) {
    console.error(e)
  }
  return null
}

const apiGetHistoryOrder = async (productBaseId) => {
  const urlApi = urlApiProductOrderPrice(productBaseId)
  try {
    const response = await http.get(urlApi)
    if (response?.data != null) {
      return response.data
    }

  } catch (e) {
    console.error(e)
  }
  return null
}

export {
  apiGetHistoryPrice,
  apiGetHistoryOrder
}
