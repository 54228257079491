<template>
  <a-modal
    v-model="visible"
    :closable="true"
    :footer="null"
    :centered="true"
    :mask-closable="true"
    :width="300"
    @cancel="onClickCancel"
    :bodyStyle="{ padding: '8px 12px' }"
  >
    <div class="text-sm text-primary-500 mb-4 font-medium">Sắp xếp</div>
    <a-radio-group
      name="sort-options"
      v-decorator="['radio-group']"
      @change="onChange"
    >
      <div class="mb-3">
        <div class="text-sm mb-2" style="color: #2e2e2e">
          Phần trăm lệch mốc theo dõi
        </div>
        <a-radio value="delta_percent__desc">Tăng > Giảm</a-radio>
        <a-radio value="delta_percent__asc">Giảm > Tăng</a-radio>
      </div>
      <div class="mb-3">
        <div class="text-sm mb-2" style="color: #2e2e2e">
          Giá lệch mốc theo dõi
        </div>
        <a-radio value="delta_price__desc">Tăng > Giảm</a-radio>
        <a-radio value="delta_price__asc">Giảm > Tăng</a-radio>
      </div>
    </a-radio-group>
    <div class="flex justify-end">
      <a-button type="primary" @click="onClickOk">Áp dụng</a-button>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: "PopupSortPriceAlertMobile",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sort: "",
    };
  },
  methods: {
    onClickOk() {
      this.$emit("submit", this.sort);
    },
    onChange(e) {
      this.sort = e.target.value;
    },
    onClickCancel() {
      this.$emit("cancel");
    },
  },
};
</script>
