import {
  urlApiGetPriceAlertList,
  urlApiCreatePriceAlert,
  urlApiUpdatedPriceAlert,
  urlApiGetPriceAlertDetail,
  urlApiVerifyProductBase,
  urlApiVerifyProductList,
  urlApiDeletePriceAlert
} from "@/api/priceAlert/PriceAlertEndpoint";
import { http } from "@/plugin/http";


const apiGetPriceAlertList = async params => {
  const urlApi = urlApiGetPriceAlertList(params);
  try {
    const response = await http.get(urlApi);
    if (response?.data != null) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
  }
  return null;
};

const apiCreatePriceAlert = async data => {
  const urlApi = urlApiCreatePriceAlert();
  try {
    const response = await http.put(urlApi, data);
    if (response?.data != null) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
  }
  return null;
};

const apiUpdatePriceAlert = async data => {
  const urlApi = urlApiUpdatedPriceAlert();
  try {
    const response = await http.post(urlApi, data);
    if (response?.data != null) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
  }
  return null;
};

const apiGetPriceAlertDetail = async id => {
  const urlApi = urlApiGetPriceAlertDetail(id);
  try {
    const response = await http.get(urlApi);
    if (response?.data != null) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
  }
  return null;
};

const apiVerifyProductBase = async value => {
  const urlApi = urlApiVerifyProductBase(value);
  try {
    const response = await http.get(urlApi);
    if (response?.data != null) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
  }
  return null;
};

const apiVerifyProductList = async product_urls => {
  const urlApi = urlApiVerifyProductList();
  try {
    const response = await http.post(urlApi, { product_urls });
    if (response?.data != null) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
  }
  return null;
};

const apiDeletePriceAlert = async id => {
  const urlApi = urlApiDeletePriceAlert(id);
  try {
    const response = await http.delete(urlApi);
    if (response?.data != null) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
  }
  return null;
};

export {
  apiGetPriceAlertList,
  apiCreatePriceAlert,
  apiUpdatePriceAlert,
  apiGetPriceAlertDetail,
  apiVerifyProductBase,
  apiVerifyProductList,
  apiDeletePriceAlert
};
